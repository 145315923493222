import { Controller } from "@hotwired/stimulus";
import { i18n } from '../i18n';
import { initializeBatchActionsView } from "../src/scripts/legacy/respBatchAction";
import Cookies from 'js-cookie';

const LOADING_HTML = `<div class="loader-container"><div class="loader-page">${i18n.t('response.loading')}</div></div>`;
const SUMMARY_TAB = "summary";
const ALL_DATA_TAB = "all-data";
const FORMS_TAB_TITLE = i18n.t('dashboard.filter_by_form');

export default class extends Controller {
  static values = {
    modifiedObjId: String,
    noWholeRowLink: Boolean
  }

  connect() {
    const responsePath = this.element.dataset.respPath;
    const formsTab = document.getElementById("forms-dropdown-tab");
    const originalFormsTabTitle = FORMS_TAB_TITLE;
    const dropdownMenu = document.querySelector('.dropdown-menu');

    this.element.querySelectorAll('[data-bs-toggle="tab"]').forEach(tab => {
      tab.addEventListener('shown.bs.tab', (event) => {
        const targetId = event.target.getAttribute("href").substring(1);
        const frameId = `${targetId}-table`;
        const frameElement = document.getElementById(frameId);

        this.loadFrameContent(frameElement, responsePath, targetId);
        this.handleCookie(targetId, responsePath, formsTab, originalFormsTabTitle);
        this.updateFormsTabTitle(formsTab, targetId);
        this.closeDropdownMenu(dropdownMenu);
        // Wait for the turbo frame to fully load before initializing the batch actions view
        document.addEventListener('turbo:frame-load', () => {
          initializeBatchActionsView(this.modifiedObjIdValue, this.noWholeRowLinkValue);
        });
        // Unload content of all other tabs
        this.unloadOtherTabs(targetId);
      });
    });

    this.checkCookieOnPageLoad();
  }

  loadFrameContent(frameElement, responsePath, targetId) {
    // Ensure currPage and items are generated correctly before setting frameElement.src
    const currPage = document.querySelector('.csync-table-responsive').dataset.currentPage || 1;
    const items = document.querySelector('.csync-table-responsive').dataset.items || 10;

    if (frameElement && frameElement.innerHTML.trim() === LOADING_HTML.trim()) {
      frameElement.src = `${responsePath}?tab=${targetId}&page=${currPage}&items=${items}`;
    }
  }

  handleCookie(targetId, responsePath, formsTab, originalFormsTabTitle) {
    if (targetId !== SUMMARY_TAB && targetId !== ALL_DATA_TAB) {
      Cookies.set('latest_form_resp', targetId, { expires: 1 });
    }

    const cookieValue = Cookies.get('latest_form_resp');
    if (cookieValue) {
      this.addRemoveIcon(cookieValue, responsePath, formsTab, originalFormsTabTitle);
    }
  }

  addRemoveIcon(cookieValue, responsePath, formsTab, originalFormsTabTitle) {
    let removeIcon = document.querySelector(".clear-tab-form");
    if (!removeIcon) {
      removeIcon = this.createRemoveIcon(responsePath, formsTab, originalFormsTabTitle);
      document.getElementById("forms-dropdown-tab").parentNode.appendChild(removeIcon);
    }

    this.addRemoveIconEventListeners(removeIcon);
  }

  createRemoveIcon(responsePath, formsTab, originalFormsTabTitle) {
    const removeIcon = document.createElement("a");
    removeIcon.className = "clear-tab-form";
    removeIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>`;
    removeIcon.addEventListener("click", () => {
      this.handleRemoveIconClick(responsePath, formsTab, originalFormsTabTitle, removeIcon);
    });

    return removeIcon;
  }

  handleRemoveIconClick(responsePath, formsTab, originalFormsTabTitle, removeIcon) {
    Cookies.remove('latest_form_resp');
    const summaryFrame = document.getElementById("summary-table");
    if (summaryFrame) {
      summaryFrame.src = `${responsePath}?tab=summary`;
    }
    removeIcon.remove();
    document.querySelector('a[href="#summary"]').click();
    formsTab.textContent = originalFormsTabTitle;
  }

  addRemoveIconEventListeners(removeIcon) {
    document.getElementById("summary-tab").addEventListener("click", () => {
      if (removeIcon) {
        removeIcon.remove();
      }
    });
    document.getElementById("all-data-tab").addEventListener("click", () => {
      if (removeIcon) {
        removeIcon.remove();
      }
    });
  }

  updateFormsTabTitle(formsTab, targetId) {
    const cookieValue = Cookies.get('latest_form_resp');
    if (formsTab && cookieValue && targetId !== ALL_DATA_TAB && targetId !== SUMMARY_TAB) {
      const formName = document.querySelector(`a[href="#${cookieValue}"]`).textContent;
      formsTab.textContent = formName;
    } else {
      formsTab.textContent = FORMS_TAB_TITLE;
    }
  }

  closeDropdownMenu(dropdownMenu) {
    if (dropdownMenu.classList.contains('show')) {
      dropdownMenu.classList.remove('show');
    }
  }

  checkCookieOnPageLoad() {
    const cookieValue = Cookies.get('latest_form_resp');
    if (cookieValue) {
      const element = document.querySelector(`a[href="#${cookieValue}"]`);
      if (element) {
        element.click();
        $(`ul#formTabs ul.dropdown-menu`).removeClass('show');
      }
    }
  }

  unloadOtherTabs(activeTabId) {
    this.element.querySelectorAll('[data-bs-toggle="tab"]').forEach(tab => {
      const targetId = tab.getAttribute("href").substring(1);
      if (targetId !== activeTabId) {
        const frameId = `${targetId}-table`;
        //console.log(frameId);
        const frameElement = document.getElementById(frameId);
        if (frameElement) {
          frameElement.innerHTML = LOADING_HTML;
        }
      }
    });
  }
}
