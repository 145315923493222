import { Controller } from "@hotwired/stimulus";
import $ from 'jquery'; // Ensure jQuery is imported
import 'select2';

import { initializeBatchActionsView } from "../src/scripts/legacy/respBatchAction";

export default class extends Controller {
  static values = {
    modifiedObjId: String,
    noWholeRowLink: Boolean
  }

  connect() {
    document.addEventListener('turbo:frame-load', () => {
      initializeBatchActionsView(this.modifiedObjIdValue, this.noWholeRowLinkValue);
    });
    this.initializeSelect2();
    this.attachEventListeners();
    this.addInitialCondition(); // Add initial form group on load
  }

  disconnect() {
    // Clean up any event listeners if needed
  }

  initializeSelect2() {
    const select2Elements = $("[id^=fieldSelect], [id^=select-operator]");
    select2Elements.each(function() {
      if ($(this).data('select2')) {
        $(this).select2('destroy'); // Destroy existing instance if it exists
      }
      $(this).select2({
        theme: "bootstrap-5",
        placeholder: 'Select an option', // Ensure placeholder is correctly set
        allowClear: true
      });
    });

    // Prevent closing the dropdown when clicking inside Select2
    select2Elements.on('select2:opening', function(e) {
      $(this).data('select2').$dropdown.off('mousedown.select2');
    });

    // Attach event listeners
    select2Elements.on('select2:select', this.updateMatchers.bind(this));
  }

  updateMatchers(event) {
    const obj = JSON.parse(event.target.value);
    const selectedField = obj.type;
    const ransackMatchers = $('.filter-modal').data('ransack-matchers');
    const data = ransackMatchers[selectedField] || [];
    const formattedData = data.map(item => ({
      id: item,
      text: i18n.t(`ransack.${item}`)
    }));

    // Update select operators
    const selectOperator = $('#select-operator-' + obj.class_group);
    
    // Clear existing options and update with new data
    selectOperator.empty();
    formattedData.forEach(option => {
      const optionElement = $('<option>', { value: option.id }).text(option.text);
      selectOperator.append(optionElement);
    });
    
    // Additional logic to handle different input types
    const formGroup = $('.search-term-' + obj.class_group);

    switch (selectedField) {
      case 'datetime':
      case 'date':
      case 'time':
        formGroup.replaceWith(`
          <input type="${selectedField}" class="search-term-${obj.class_group} form-control" name="q[search_term]" placeholder="${i18n.t('filter.value')}" />
        `);
        break;
      case 'boolean':
        formGroup.replaceWith(`
          <select class="select2 search-term-${obj.class_group} form-select" name="q[search_term]" placeholder="${i18n.t('filter.value')}">
            <option value="true">${i18n.t("common._yes")}</option>
            <option value="false">${i18n.t("common._no")}</option>
          </select>
        `);
        break;
      default:
        formGroup.replaceWith(`
          <input type="text" class="search-term-${obj.class_group} form-control" name="q[search_term]" placeholder="${i18n.t('filter.value')}" />
        `);
    }

    selectOperator.trigger('change');
  }  

  addCondition(event) {
    const formGroup = this.createFormGroup();
    $('.filter-form').append(formGroup);
    this.initializeSelect2InFormGroup(formGroup);
  }

  addInitialCondition() {
    const formGroup = this.createFormGroup();
    $('.filter-form').append(formGroup);
    this.initializeSelect2InFormGroup(formGroup);
  }

  createFormGroup() {
    const formGroupCount = $('.filter-form .form-group').length + 1;
    const formGroupClass = `form-group-${formGroupCount}`;
    const fields = $('.filter-modal').data('filtered-fields');
  
    const formGroup = $(`
      <div class="form-group row gx-2 align-items-center p-3 ${formGroupClass}">
        <div class="col-" style="width: 250px;">
          <select id="fieldSelect-${formGroupClass}" data-placeholder="${i18n.t('filter.column')}" class="form-select select2" name="q[header_name_eq]">
            <option></option> <!-- Ensure an empty option is present for placeholder -->
          </select>
        </div>
        <div class="col-auto" style="width: 250px;">
          <select id="select-operator-${formGroupClass}" data-placeholder="${i18n.t('filter.operator')}" class="form-select select2" name="q[matcher_eq]">
            <option></option> <!-- Ensure an empty option is present for placeholder -->
          </select>
        </div>
        <div class="col-auto" style="width: 150px;">
          <input type="text" class="search-term-${formGroupClass} form-control" name="q[search_term]" placeholder="${i18n.t('filter.value')}" />
        </div>
        <div class="col-auto">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="remove-condition-filter bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
          </svg>
        </div>
      </div>
    `);
  
    formGroup.find('.remove-condition-filter').on('click', () => {
      formGroup.remove();
    });
  
    // Add options to fieldSelect
    const fieldSelect = formGroup.find(`#fieldSelect-${formGroupClass}`);
    fields.forEach(option => {
      const optionValue = JSON.stringify(Object.assign({}, option.id, { class_group: `${formGroupClass}` }));
      const optionElement = $('<option>', { value: optionValue }).text(option.text);
      fieldSelect.append(optionElement);
    });

    return formGroup;
  }  

  initializeSelect2InFormGroup(formGroup) {
    const select2Elements = formGroup.find('select');
    select2Elements.each(function() {
      if ($(this).data('select2')) {
        $(this).select2('destroy'); // Destroy existing instance if it exists
      }
      $(this).select2({
        theme: "bootstrap-5",
        allowClear: true
      });
    });

    // Prevent closing the dropdown when clicking inside Select2
    select2Elements.on('select2:opening', function(e) {
      $(this).data('select2').$dropdown.off('mousedown.select2');
    });

    // Attach event listeners
    select2Elements.on('select2:select', this.updateMatchers.bind(this));
  }

  closeDropdown(event) {
    const dropdownMenu = $(event.target).closest('.dropdown-menu');
    dropdownMenu.removeClass('show');
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const formGroups = $('.filter-form .form-group');
    const formData = [];

    formGroups.each(function() {
      const formGroup = $(this);
      const field = formGroup.find('select[name="q[header_name_eq]"]').val();
      const operator = formGroup.find('select[name="q[matcher_eq]"]').val();
      const term = formGroup.find('input[name="q[search_term]"], select[name="q[search_term]"]').val();

      if (field && operator && term) {
        formData.push({
          field: JSON.parse(field),
          operator: operator,
          term: term
        });
      }
    });

    console.log("Collected form data:", formData);

    // Submit the form data via AJAX or any other method as needed
    $.ajax({
      url: '/path/to/submit', // Replace with the correct endpoint
      method: 'POST',
      data: { filters: formData },
      success: function(response) {
        console.log("Form submission successful:", response);
        // Handle successful response
      },
      error: function(xhr, status, error) {
        console.log("Form submission failed:", error);
        // Handle error response
      }
    });
  }

  attachEventListeners() {
    const addConditionButton = document.querySelector('.add-filter-btn');
    const closeDropdownButton = document.querySelector('.close-dropdown-btn');
    const filterForm = document.querySelector('.filter-form').closest('form');
    const submitFormButton = document.querySelector('.submit-form-btn');

    if (addConditionButton) {
      addConditionButton.addEventListener('click', this.addCondition.bind(this));
    }

    if (closeDropdownButton) {
      closeDropdownButton.addEventListener('click', this.closeDropdown.bind(this));
    }

    if (submitFormButton) {
      submitFormButton.addEventListener('click', this.handleFormSubmit.bind(this));
    }

    if (filterForm) {
      filterForm.addEventListener('submit', this.handleFormSubmit.bind(this));
    }
  }
}
