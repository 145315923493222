// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
window.Stimulus = Application.start()
Turbo.session.drive = false
const context = require.context("../controllers", true, /\.js$/)

Stimulus.load(definitionsFromContext(context))

