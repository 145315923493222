//

import { Controller } from "@hotwired/stimulus"
import { initializeBatchActionsView } from "../src/scripts/legacy/respBatchAction";

export default class extends Controller {
  static targets = ["select"]
  static values = {
    modifiedObjId: String,
    noWholeRowLink: Boolean
  }

  connect() {
    this.selectTarget.addEventListener("change", this.changeItemsNumber.bind(this))
    // Wait for the turbo frame to fully load before initializing the batch actions view
    document.addEventListener('turbo:frame-load', () => {
      initializeBatchActionsView(this.modifiedObjIdValue, this.noWholeRowLinkValue);
    });
  }

  changeItemsNumber(event) {
    const pageItems = event.target.value;
    const url = `${window.location.pathname}?items=${pageItems}`;
    Turbo.visit(url, { action: "replace", historyChanged: true })
  }
}
