import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { initializeBatchActionsView } from "../src/scripts/legacy/respBatchAction";

export default class extends Controller {
  static values = {
    modifiedObjId: String,
    noWholeRowLink: Boolean
  };

  connect() {
    const inputTargets = this.inputTargets;
    const buttonTargets = this.buttonTargets;

    // Handle Enter key press for all input targets
    inputTargets.forEach(inputTarget => {
      inputTarget.addEventListener("keypress", (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          this.goToPage(inputTarget.value);
        }
      });
    });

    // Handle click for all button targets
    buttonTargets.forEach(buttonTarget => {
      buttonTarget.addEventListener("click", (event) => {
        event.preventDefault();
        this.goToPageUrl(buttonTarget.getAttribute("href"));
      });
    });

    // Wait for the turbo frame to fully load before initializing the batch actions view
    document.addEventListener('turbo:frame-load', () => {
      initializeBatchActionsView(this.modifiedObjIdValue, this.noWholeRowLinkValue);
    });
  }

  goToPage(pageNumber) {
    const url = `${window.location.pathname}?page=${pageNumber}`;

    // Use Turbo Streams to replace the content of the turbo frame with the new page content
    Turbo.visit(url, { action: "replace", historyChanged: true });
  }

  goToPageUrl(pageUrl) {
    // Use Turbo Streams to replace the content of the turbo frame with the new page content
    Turbo.visit(pageUrl, { action: "replace", historyChanged: true });
  }

  get inputTargets() {
    return this.element.querySelectorAll(".pagy-bootstrap input.page-link");
  }

  get buttonTargets() {
    return this.element.querySelectorAll(".pagination li.page-item a.page-link:not([aria-disabled='true'])");
  }
}